// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageSuggestionVariantDetailsService } from '../services/image-suggestion-variant-details.service';
import {
  getImageSuggestionVariantDetailsFailed,
  getImageSuggestionVariantDetailsNextPageFailed,
  getImageSuggestionVariantDetailsNextPageRequested,
  getImageSuggestionVariantDetailsNextPageSucceeded,
  getImageSuggestionVariantDetailsRequested,
  getImageSuggestionVariantDetailsSucceeded,
} from './image-suggestion-variant-details.actions';
import { SnackbarService } from '@par/app/core/services/snackbar.service';

@Injectable()
export class ImageSuggestionVariantDetailsEffects {

  detailsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageSuggestionVariantDetailsRequested),
      switchMap((action) => this.service.getImageSuggestionVariantDetails(action.payload)
        .pipe(
          map((response) => getImageSuggestionVariantDetailsSucceeded({response})),
          catchError((httpErrorResponse) => of(getImageSuggestionVariantDetailsFailed({httpErrorResponse}))),
        )),
    ),
  );

  nextPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageSuggestionVariantDetailsNextPageRequested),
      switchMap((action) => this.service.getImageSuggestionVariantDetails(action.payload)
        .pipe(
          map((response) => getImageSuggestionVariantDetailsNextPageSucceeded({response})),
          catchError((httpErrorResponse) => of(getImageSuggestionVariantDetailsNextPageFailed({httpErrorResponse}))),
        )),
    ),
  );

  showError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageSuggestionVariantDetailsNextPageFailed, getImageSuggestionVariantDetailsFailed),
      map(({httpErrorResponse}) => this.snackbarService.error(httpErrorResponse.error['detail'])),
    ), {dispatch: false},
  );


  constructor(
    private actions$: Actions,
    private service: ImageSuggestionVariantDetailsService,
    private snackbarService: SnackbarService,
  ) {
  }
}
