// :cow: Cosmose CONFIDENTIAL :iso:
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';
import { getConfig } from './app/core/config/get-config';
import { LIGHTBOX_CONFIG, LightboxModule } from 'ng-gallery/lightbox';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/core/interceptors/auth.interceptor';
import { bootstrapApplication } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  imageSuggestionVariantListReducer,
  STATE_NAME_IMAGE_SUGGESTION_VARIANT_LIST,
} from '@par/app/shared/image-suggestion-variant-list-dialog/store/image-suggestion-variant-list.reducer';
import {
  imageSuggestionVariantDetailsReducer,
  STATE_NAME_IMAGE_SUGGESTION_VARIANT_DETAILS,
} from '@par/app/shared/image-suggestion-variant-details-dialog/store/image-suggestion-variant-details.reducer';
import {
  selectVideoProviderReducer,
  STATE_NAME_SELECT_VIDEO_PROVIDER,
} from '@par/app/shared/select-video-provider/store/select-video-provider.reducer';
import { selectTagReducer, STATE_NAME_SELECT_TAG } from '@par/app/shared/select-tag/store/select-tag.reducer';
import {
  selectPublicationReducer,
  STATE_NAME_SELECT_PUBLICATION,
} from '@par/app/shared/select-publication/store/select-publication.reducer';
import {
  selectImageProviderReducer,
  STATE_NAME_SELECT_IMAGE_PROVIDER,
} from '@par/app/shared/select-image-provider/store/select-image-provider.reducer';
import { selectCategoryReducer, STATE_NAME_SELECT_CATEGORY } from '@par/app/shared/select-category/store/select-category.reducer';
import { selectAdvertiserReducer, STATE_NAME_SELECT_ADVERTISER } from '@par/app/shared/select-advertiser/store/select-advertiser.reducer';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { ImageSuggestionVariantListEffects } from '@par/app/shared/image-suggestion-variant-list-dialog/store/image-suggestion-variant-list.effects';
import { ImageSuggestionVariantDetailsEffects } from '@par/app/shared/image-suggestion-variant-details-dialog/store/image-suggestion-variant-details.effects';
import { SelectVideoProviderEffects } from '@par/app/shared/select-video-provider/store/select-video-provider.effects';
import { SelectTagEffects } from '@par/app/shared/select-tag/store/select-tag.effects';
import { SelectPublicationEffects } from '@par/app/shared/select-publication/store/select-publication.effects';
import { SelectImageProviderEffects } from '@par/app/shared/select-image-provider/store/select-image-provider.effects';
import { SelectCategoryEffects } from '@par/app/shared/select-category/store/select-category.effects';
import { SelectAdvertiserEffects } from '@par/app/shared/select-advertiser/store/select-advertiser.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app/app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { APP_CONFIG } from '@par/app/core/services/config.injection-token';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from '@par/app/app.routes';

const config = getConfig(document.location.host);
Object.freeze(config);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en_US',
        extend: true,
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
          deps: [HttpClient],
        },
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerImmediately',
      }),

      StoreModule.forRoot({}, {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }),
      EffectsModule.forRoot(),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      StoreRouterConnectingModule.forRoot(),
      NgxSpinnerModule,
      MatNativeDateModule,
      LightboxModule,
      ToastrModule.forRoot(),
      MatDialogModule,
    ),
    provideStore(
      {
        [STATE_NAME_IMAGE_SUGGESTION_VARIANT_LIST]: imageSuggestionVariantListReducer,
        [STATE_NAME_IMAGE_SUGGESTION_VARIANT_DETAILS]: imageSuggestionVariantDetailsReducer,
        [STATE_NAME_SELECT_VIDEO_PROVIDER]: selectVideoProviderReducer,
        [STATE_NAME_SELECT_TAG]: selectTagReducer,
        [STATE_NAME_SELECT_PUBLICATION]: selectPublicationReducer,
        [STATE_NAME_SELECT_IMAGE_PROVIDER]: selectImageProviderReducer,
        [STATE_NAME_SELECT_CATEGORY]: selectCategoryReducer,
        [STATE_NAME_SELECT_ADVERTISER]: selectAdvertiserReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    provideEffects(
      ImageSuggestionVariantListEffects,
      ImageSuggestionVariantDetailsEffects,
      SelectVideoProviderEffects,
      SelectTagEffects,
      SelectPublicationEffects,
      SelectImageProviderEffects,
      SelectCategoryEffects,
      SelectAdvertiserEffects,
    ),
    {provide: LOCALE_ID, useValue: 'en-US'},
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    provideAnimationsAsync(),
    {provide: APP_CONFIG, useValue: config},
    provideRouter(appRoutes, withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    })),
  ],
})
  .catch(err => console.error(err));
