// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageSuggestionVariantListService } from '../services/image-suggestion-variant-list.service';
import {
  getImageSuggestionVariantListFailed,
  getImageSuggestionVariantListRequested,
  getImageSuggestionVariantListSucceeded,
} from './image-suggestion-variant-list.actions';
import { SnackbarService } from '@par/app/core/services/snackbar.service';

@Injectable()
export class ImageSuggestionVariantListEffects {

  getImageSuggestionVariantListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageSuggestionVariantListRequested),
      switchMap((action) => this.service.getImageSuggestionVariantList(action.payload)
        .pipe(
          map((response) => getImageSuggestionVariantListSucceeded({response})),
          catchError((httpErrorResponse) => of(getImageSuggestionVariantListFailed({httpErrorResponse}))),
        )),
    ),
  );

  showError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageSuggestionVariantListFailed),
      map(({httpErrorResponse}) => this.snackbarService.error(httpErrorResponse.error['detail'])),
    ), {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private service: ImageSuggestionVariantListService,
    private snackbarService: SnackbarService,
  ) {
  }
}
