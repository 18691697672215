// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getTagListFailed, getTagListRequested, getTagListSucceeded } from './select-tag.actions';
import { DictionariesService } from '@par/app/core/data-services/dictionaries.service';

@Injectable()
export class SelectTagEffects {

  listRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTagListRequested),
      mergeMap(() => this.service.getTagList()
        .pipe(
          map((response) => getTagListSucceeded({response})),
          catchError(() => of(getTagListFailed())),
        )),
    ),
  );

  constructor(
    private actions$: Actions,
    private service: DictionariesService,
  ) {
  }
}
