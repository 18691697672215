// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getPublicationListFailed, getPublicationListRequested, getPublicationListSucceeded } from './select-publication.actions';
import { DictionariesService } from '@par/app/core/data-services/dictionaries.service';

@Injectable()
export class SelectPublicationEffects {

  listRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPublicationListRequested),
      mergeMap(() => this.service.getPublicationList()
        .pipe(
          map((response) => getPublicationListSucceeded({response})),
          catchError(() => of(getPublicationListFailed())),
        )),
    ),
  );

  constructor(
    private actions$: Actions,
    private service: DictionariesService,
  ) {
  }
}
