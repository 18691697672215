// :cow: Cosmose CONFIDENTIAL :iso:
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import en_US from '../assets/i18n/en_US.json';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'kkna-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    private translateService: TranslateService,
  ) {
    translateService.setTranslation('en_US', en_US);

  }
}

