// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getImageProviderListFailed, getImageProviderListRequested, getImageProviderListSucceeded } from './select-image-provider.actions';
import { DictionariesService } from '@par/app/core/data-services/dictionaries.service';

@Injectable()
export class SelectImageProviderEffects {

  listRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImageProviderListRequested),
      mergeMap(() => this.service.getImageProviderList()
        .pipe(
          map((response) => getImageProviderListSucceeded({response})),
          catchError(() => of(getImageProviderListFailed())),
        )),
    ),
  );

  constructor(
    private actions$: Actions,
    private service: DictionariesService,
  ) {
  }
}
