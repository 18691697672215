// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getAdvertiserListFailed, getAdvertiserListRequested, getAdvertiserListSucceeded } from './select-advertiser.actions';
import { DictionariesService } from '@par/app/core/data-services/dictionaries.service';

@Injectable()
export class SelectAdvertiserEffects {

  listRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdvertiserListRequested),
      mergeMap(() => this.service.getAdvertiserList()
        .pipe(
          map((response) => getAdvertiserListSucceeded({response})),
          catchError(() => of(getAdvertiserListFailed())),
        )),
    ),
  );

  constructor(
    private actions$: Actions,
    private service: DictionariesService,
  ) {
  }
}
