// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AdvertiserListResponse, CategoryListResponse, DictionariesResponse } from '../model/dictionaries.response';
import { ConfigService } from '@par/app/core/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  private dictionaries$: Observable<DictionariesResponse>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  getCategoryList(): Observable<CategoryListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'categories');
          return dictionary.values.map((element) => ({label: element.name.toString(), value: element.id.toString()}));
        }),
      );
  }

  getImageProviderList(): Observable<CategoryListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'imageProviders');
          return dictionary.values.map((element) => ({label: element.name.toString(), value: element.id.toString()}));
        }),
      );
  }

  getVideoProviderList(): Observable<CategoryListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'videoProviders');
          return dictionary.values.map((element) => ({label: element.name.toString(), value: element.id.toString()}));
        }),
      );
  }

  getAdvertiserList(): Observable<AdvertiserListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'advertiser');
          return dictionary.values.map((element) => ({label: element.name.toString(), value: element.id.toString()}));
        }),
      );
  }

  getTagList(): Observable<CategoryListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'tags');
          return dictionary.values.map((element) => ({label: element.name.toString(), value: element.id.toString()}));
        }),
      );
  }

  getPublicationList(): Observable<CategoryListResponse> {
    return this.getDictionaries()
      .pipe(
        map((dictionaries) => {
          const dictionary = dictionaries.find(dictionary => dictionary.name === 'publication');
          return dictionary.values.map((element) => ({
            label: element.name.toString(),
            value: element.id.toString(),
            manualArticleCreationEnabled: element.manualArticleCreationEnabled,
          }));
        }),
      );
  }

  getDictionaries(): Observable<DictionariesResponse> {
    if (!this.dictionaries$) {
      const url = this.configService.getConfig().kknowContentApi + '/dictionaries';
      this.dictionaries$ = this.httpClient.get<DictionariesResponse>(url)
        .pipe(shareReplay({bufferSize: 1, refCount: true}));
    }

    return this.dictionaries$;
  }
}
